body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo img {
  transition: 300ms ease;
}

.logo:hover img {
  transform: rotate(20deg);
}

.br-10 {
  border-radius: 10px 10px 0px 0px;
}
